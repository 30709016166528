<template>

    <section>
      <Header/>
     <!-- Video Section -->
     <section class="main">
       <Feedback />

      <!-- Intro after Video -->
      <section>
        <div class="introText" style="text-align:center;color:#0A2940;font-size:29px;">
          <b>{{ content[setLang].intro_title }}</b>
        </div>
        <div class="introText" style="text-align:center;color:#0A2940;font-size:23px;">
          {{ content[setLang].intro_text }}
        </div>
      </section>

      <section class="introText">
      
      <div style="position:relative;display:inline;">
        <img class="img-fluid w-100" src="../assets/front_video.png" alt="ODSS Video" />
        <img class="img-fluid"  src="../assets/video_play.svg" alt="ODSS Video Play" @click="openGallery(0)" style="
        position:absolute;top:1%;left:42%;cursor:pointer;width:15%;"/>
      </div>
     
        <p style="width:100%;background-color:#0A2940;font-size:22px;color:white;padding:5px 10px 5px 25px;margin:0;cursor:pointer;" @click="openGallery(0)">{{content[setLang].watch_video}}</p>
        <p style="float:right;color:#0A2940;font-size:22px;padding:5px 10px 5px 25px;"><router-link :to="'/transcript?locale='+setLang" class="topNavLink">{{content[setLang].transcript}}</router-link></p>
        <LightBox
        ref="lightbox"
        :key="vidKey"
        :media="vidsrc"
        :show-caption="false"
        :show-light-box="false"
        :show-thumbs="false"
        />
        <br/>
      </section>
      
      <!-- List sectors -->
      <section id="sectors">
        <h2 class="p-4" style="text-align:center;font-size:35px;">{{content[setLang].learn_sec}}</h2>
        <!-- 
          = need to grab these from database and loop through each to display images
          = make sure if a second row gets added, it doesn't mess up the text
          = use v-for / v-if
        -->
        <div class="container">
          <div class="row">
            <div class="col-sm-2"></div>
            <div class="col-sm-2 mb-4 text-center">
              <router-link :to="'/sector?id=1'+locpath" class="routerLink">
              <img class="img-fluid" src="../assets/construction_icon.svg" alt="Construction Sector" />
              </router-link>
              <br/>
              <router-link  :to="'/sector?id=1'+locpath"><p class="main-views-captions font-weight-bold mt-3">Construction</p></router-link>
            </div>
            <div class="col-sm-2 mb-4 text-center">
              <router-link :to="'/sector?id=9'+locpath" class="routerLink">
              <img class="img-fluid" src="../assets/foodbeverage_icon.svg" alt="Food and Beverage Sector" />
              </router-link>
              <br/>
              <router-link :to="'/sector?id=9'+locpath" v-if="setLang == 'fr'"><p class="main-views-captions font-weight-bold mt-3">Aliments et boissons</p></router-link>
              <router-link :to="'/sector?id=9'+locpath" v-else><p class="main-views-captions font-weight-bold mt-3">Food and Beverage</p></router-link>
            </div>
            <div class="col-sm-2 mb-4 text-center">
              <router-link :to="'/sector?id=3'+locpath" class="routerLink">
              <img class="img-fluid" src="../assets/healthcare_icon.svg" alt="Healthcare Sector" />
              </router-link>
              <br/>
              <router-link :to="'/sector?id=3'+locpath" v-if="setLang == 'fr'"><p class="main-views-captions font-weight-bold mt-3">Soins de santé</p></router-link>
              <router-link :to="'/sector?id=3'+locpath" v-else><p class="main-views-captions font-weight-bold mt-3">Healthcare</p></router-link>
            </div>
            <div class="col-sm-2"></div>
          </div>
          <div class="row">
            <div class="col-sm-2"></div>
            <div class="col-sm-2 mb-4 text-center">
              <router-link :to="'/sector?id=7'+locpath" class="routerLink">
              <img class="img-fluid" src="../assets/metalmanufacturing_icon.svg" alt="Metal Sector" />
              </router-link>
              <br/>
              <router-link :to="'/sector?id=7'+locpath" v-if="setLang == 'fr'"><p class="main-views-captions font-weight-bold mt-3">Metal Manufacturing</p></router-link>
              <router-link :to="'/sector?id=7'+locpath" v-else><p class="main-views-captions font-weight-bold mt-3">Metal Manufacturing</p></router-link>
            </div>
            <div class="col-sm-2 mb-3 text-center">
              <router-link :to="'/sector?id=2'+locpath" class="routerLink">
              <img class="img-fluid" src="../assets/mining_icon.svg" alt="Mining Sector" /> 
              </router-link>
              <br/>
              <router-link :to="'/sector?id=2'+locpath" v-if="setLang == 'fr'"><p class="main-views-captions font-weight-bold mt-3">Exploitation minière</p></router-link>
              <router-link :to="'/sector?id=2'+locpath" v-else><p class="main-views-captions font-weight-bold mt-3">Mining</p></router-link>
            </div>
            <div class="col-sm-2 mb-3 text-center">
              <router-link :to="'/sector?id=11'+locpath" class="routerLink">
              <img class="img-fluid" src="../assets/plasticproducts_icon.svg" alt="Plastic Products Sector" />
              </router-link>
              <br/>
              <router-link :to="'/sector?id=11'+locpath" v-if="setLang == 'fr'"><p class="main-views-captions font-weight-bold mt-3">Fabrication de plastiques</p></router-link>
              <router-link :to="'/sector?id=11'+locpath" v-else><p class="main-views-captions font-weight-bold mt-3">Plastic Products</p></router-link>
            </div>
            <div class="col-sm-2"></div>
          </div>
          <div class="row">
            <div class="col-sm-2"></div>
            <div class="col-sm-2 mb-4 text-center">
              <router-link :to="'/sector?id=8'+locpath" class="routerLink">
              <img class="img-fluid" src="../assets/protective_icon.svg" alt="Protective Services Sector" />
              </router-link>
              <br/>
              <router-link :to="'/sector?id=8'+locpath" v-if="setLang == 'fr'"><p class="main-views-captions font-weight-bold mt-3">Services de protection</p></router-link>
              <router-link :to="'/sector?id=8'+locpath" v-else><p class="main-views-captions font-weight-bold mt-3">Protective Services</p></router-link>
            </div>
            <div class="col-sm-2 mb-3 text-center">
              <router-link :to="'/sector?id=10'+locpath" class="routerLink">
              <img class="img-fluid" src="../assets/rubberproducts_icon.svg" alt="Rubber Products Sector" />
              </router-link>
              <br/>
              <router-link :to="'/sector?id=10'+locpath" v-if="setLang == 'fr'"><p class="main-views-captions font-weight-bold mt-3">Produits du caoutchouc</p></router-link>
              <router-link :to="'/sector?id=10'+locpath" v-else><p class="main-views-captions font-weight-bold mt-3">Rubber Products</p></router-link>
            </div>
            <div class="col-sm-2 mb-4 text-center">
              <router-link :to="'/sector?id=6'+locpath" class="routerLink">
              <img class="img-fluid" src="../assets/transportation_icon.svg" alt="Transportation Sector" />
              </router-link>
              <br/>
              <router-link :to="'/sector?id=6'+locpath" v-if="setLang == 'fr'"><p class="main-views-captions font-weight-bold mt-3">Transports</p></router-link>
              <router-link :to="'/sector?id=6'+locpath" v-else><p class="main-views-captions font-weight-bold mt-3">Transportation</p></router-link>
            </div>
            <div class="col-sm-2"></div>
          </div>
        </div>
      </section>

      <br/>

      <!-- List Exposures -->
      <section id="sectors" style="margin-bottom:100px;">
        <h2 class="p-4" style="text-align:center;font-size:35px;">{{content[setLang].learn_exp}}</h2>
        <div class="row">
          <div class="col-sm-2"></div>
          <div class="col-sm-2 mb-4 text-center">
            <router-link :to="'/exposure?id=15'+locpath" class="routerLink">
            <img class="img-fluid" src="../assets/asbestos_icon.svg" alt="Asbestos Exposure" />
            </router-link>
            <br/>
            <router-link :to="'/exposure?id=15'+locpath" v-if="setLang == 'fr'"><p class="main-views-captions font-weight-bold mt-3">Amiante</p></router-link>
            <router-link :to="'/exposure?id=15'+locpath" v-else><p class="main-views-captions font-weight-bold mt-3">Asbestos</p></router-link>
          </div>
          <div class="col-sm-2 mb-4 text-center">
            <router-link :to="'/exposure?id=10'+locpath" class="routerLink">
            <img class="img-fluid" src="../assets/cleaningagents_icon.svg" alt="Cleaning Agents Exposure" />
            </router-link>
            <br/>
            <router-link :to="'/exposure?id=10'+locpath" v-if="setLang == 'fr'"><p class="main-views-captions font-weight-bold mt-3">Produits de nettoyage</p></router-link>
            <router-link :to="'/exposure?id=10'+locpath" v-else><p class="main-views-captions font-weight-bold mt-3">Cleaning agents</p></router-link>
          </div>
          <div class="col-sm-2 mb-4 text-center">
            <router-link :to="'/exposure?id=2'+locpath" class="routerLink">
            <img class="img-fluid" src="../assets/dieselengineexhaust_icon.svg" alt="Diesel Engine Exhaust Exposure" />
            </router-link>
            <br/>
            <router-link :to="'/exposure?id=2'+locpath" v-if="setLang == 'fr'"><p class="main-views-captions font-weight-bold mt-3">Gaz d'échappement des moteurs diesel</p></router-link>
            <router-link :to="'/exposure?id=2'+locpath" v-else><p class="main-views-captions font-weight-bold mt-3">Diesel engine exhaust</p></router-link>
          </div>
          <div class="col-sm-2"></div>
        </div>
        <div class="row">
          <div class="col-sm-2"></div>

          <div class="col-sm-2 mb-4 text-center">
            <router-link :to="'/exposure?id=14'+locpath" class="routerLink">
            <img class="img-fluid" src="../assets/flourandgraindust_icon.svg" alt="Flour and grain dust Exposure" />
            </router-link>
            <br/>
            <router-link :to="'/exposure?id=14'+locpath" v-if="setLang == 'fr'"><p class="main-views-captions font-weight-bold mt-3">Poussière de céréales et de farine</p></router-link>
            <router-link :to="'/exposure?id=14'+locpath" v-else><p class="main-views-captions font-weight-bold mt-3">Grain and flour dust</p></router-link>
          </div>
          <div class="col-sm-2 mb-4 text-center">
            <router-link :to="'/exposure?id=11'+locpath" class="routerLink">
            <img class="img-fluid" src="../assets/nickel_icon.svg" alt="Nickel Exposure" />
            </router-link>
            <br/>
            <router-link :to="'/exposure?id=11'+locpath" v-if="setLang == 'fr'"><p class="main-views-captions font-weight-bold mt-3">Nickel</p></router-link>
            <router-link :to="'/exposure?id=11'+locpath" v-else><p class="main-views-captions font-weight-bold mt-3">Nickel</p></router-link>
          </div>
          <div class="col-sm-2 mb-4 text-center">
            <router-link :to="'/exposure?id=5'+locpath" class="routerLink">
            <img class="img-fluid" src="../assets/polycyclicaromatichydrocarbons_icon.svg" alt="Polycyclic aromatic hydrocarbons Exposure" />
            </router-link>
            <br/>
            <router-link :to="'/exposure?id=5'+locpath" v-if="setLang == 'fr'"><p class="main-views-captions font-weight-bold mt-3">Hydrocarbures aromatiques polycycliques</p></router-link>
            <router-link :to="'/exposure?id=5'+locpath" v-else><p class="main-views-captions font-weight-bold mt-3">Polycyclic aromatic hydrocarbons</p></router-link>
          </div>
          <div class="col-sm-2"></div>
        </div>
        <div class="row">
          <div class="col-sm-2"></div>
          <div class="col-sm-2 mb-4 text-center">
            <router-link :to="'/exposure?id=1'+locpath" class="routerLink">
            <img class="img-fluid" src="../assets/silica_icon.svg" alt="Silica Exposure" />
            </router-link>
            <br/>
            <router-link :to="'/exposure?id=1'+locpath" v-if="setLang == 'fr'"><p class="main-views-captions font-weight-bold mt-3">Silice</p></router-link>
            <router-link :to="'/exposure?id=1'+locpath" v-else><p class="main-views-captions font-weight-bold mt-3">Silica</p></router-link>
          </div>
          <div class="col-sm-2 mb-4 text-center">
            <router-link :to="'/exposure?id=3'+locpath" class="routerLink">
            <img class="img-fluid" src="../assets/uvradiation_icon.svg" alt="UV Radiation Exposure" />
            </router-link>
            <br/>
            <router-link :to="'/exposure?id=3'+locpath" v-if="setLang == 'fr'"><p class="main-views-captions font-weight-bold mt-3">Rayonnement UV</p></router-link>
            <router-link :to="'/exposure?id=3'+locpath" v-else><p class="main-views-captions font-weight-bold mt-3">UV radiation</p></router-link>
          </div>
          <div class="col-sm-2 mb-4 text-center">
            <router-link :to="'/exposure?id=6'+locpath" class="routerLink">
            <img class="img-fluid" src="../assets/weldingfumes_icon.svg" alt="Welding Fumes Exposure" />
            </router-link>
            <br/>
            <router-link :to="'/exposure?id=6'+locpath" v-if="setLang == 'fr'"><p class="main-views-captions font-weight-bold mt-3">Fumées de soudage</p></router-link>
            <router-link :to="'/exposure?id=6'+locpath" v-else><p class="main-views-captions font-weight-bold mt-3">Welding fumes</p></router-link>
          </div>
          <div class="col-sm-2"></div>
        </div>
        <div class="row">
          <div class="col-sm-2"></div>

          <div class="col-sm-2 mb-3 text-center">
            <router-link :to="'/exposure?id=4'+locpath" class="routerLink">
            <img class="img-fluid" src="../assets/wooddust_icon.svg" alt="Wood Dust Exposure" />
            </router-link>
            <br/>
            <router-link :to="'/exposure?id=4'+locpath" v-if="setLang == 'fr'"><p class="main-views-captions font-weight-bold mt-3">Poussière de bois</p></router-link>
            <router-link :to="'/exposure?id=4'+locpath" v-else><p class="main-views-captions font-weight-bold mt-3">Wood dust</p></router-link>
          </div>
          <div class="col-sm-2 mb-4 text-center">
          </div>
          <div class="col-sm-2 mb-4 text-center">
          </div>
          <div class="col-sm-2"></div>
        </div>
      </section>



      </section>
      <Footer/>
      <!-- <script type="application/javascript">(function(t,e,s,n){var o,a,c;t.SMCX=t.SMCX||[],e.getElementById(n)||(o=e.getElementsByTagName(s),a=o[o.length-1],c=e.createElement(s),c.type="application/javascript",c.async=!0,c.id=n,c.src="https://widget.surveymonkey.com/collect/website/js/tRaiETqnLgj758hTBazgd9H5EX_2B27vj6m_2FMv9qEvml53ClB0Ye9ftC01dUoxWHCa.js",a.parentNode.insertBefore(c,a))})(window,document,"script","smcx-sdk");</script> -->

    </section>


    
</template>

<script>
// Imports
import Vue from 'vue'
import VueHead from 'vue-head'
import Header from './Header.vue'
import Footer from './Footer.vue'
import Feedback from '../components/feedback.vue'
import VueAxios from 'vue-axios'
import axios from 'axios'

Vue.use(VueAxios, axios)
Vue.use(VueHead)

import LightBox from 'vue-it-bigger'
Vue.use(LightBox)
require('vue-it-bigger/dist/vue-it-bigger.min.css');
export default {
  name: "app",
  components: {
    Header,
    Footer,
    LightBox,
    Feedback
  },
  data() {
    return {
      errorMsg: "",
      sectors: [],
      media_fr: [
        {
          thumb: '../img/front_video.png',
          sources: [
            {
              src:  '../img/ODDS-V7-f.mp4',
              type: 'video/mp4',
            },
          ],
          type: 'video',
          caption: 'ODSS - Video about Workplaces',
          width: 800,
          height: 500,
        },
      ],
      media_en: [
        {
          thumb: '../img/front_video.png',
          sources: [
            {
              src:  '../img/ODDS-V5.mp4',
              type: 'video/mp4',
            },
          ],
          type: 'video',
          caption: 'ODSS - Video about Workplaces',
          width: 800,
          height: 500,
        },
      ],
      gallery: [
        {
          thumbnail: '../assets/front_video.png'
        },
      ],
      content: {
        en: {
          intro_title: 'Identify worker groups at risk from hazardous substances in the workplace',
          intro_text: 'Explore the connection between workplace exposures and occupational disease in Ontario. Use our Data Tool to dive deeper into the risks among different sectors so we can make workplaces safer.',
          watch_video: "Watch the video",
          transcript: "Transcript",
          learn_sec: "Learn more about these sectors",
          learn_exp: "Learn more about exposures",
        },
        fr: {
          intro_title: 'Identifier les groupes de travailleurs exposés aux risques des substances dangereuses en milieu de travail',
          intro_text: "Explorer les liens entre l'exposition en milieu de travail et les maladies professionnelles en Ontario. Utilisez notre outil de données pour explorer les risques dans les différents secteurs, pour que les milieux de travail soient plus sécuritaires.",
          watch_video: "Voir la vidéo",
          transcript: "Transcription",
          learn_sec: "En savoir plus sur ces secteurs",
          learn_exp: "En savoir plus sur l'exposition",
        }
      }
    }
  },
  computed:{
    setLang : function () {
      var lang = (this.$route.query.locale) ? this.$route.query.locale : 'en';
      return lang;
    },
    locpath: function() {
      console.log(this.setLang);
      var lang = (this.$route.query.locale) ? this.$route.query.locale : 'en';
      return '&locale='+lang;
    },
    vidsrc: function() {
      if (this.setLang == 'fr') {
        return this.media_fr;
      }else{
        return this.media_en;
      }
    },
    vidKey: function() {
      // we set ":key" on lightbox so it has to destroy/recreate
      // for the correct video language to load 
      if (this.setLang == 'fr') {
        return 'vidfr';
      }else{
        return 'viden';
      }
    }
  },
  mounted: function(){
    this.getSectors();
  },
  methods: {
    getSectors() {
      var urloc = window.location.origin;
      axios.post(urloc+"/php/data.php?action=getSectors").then(response => {
        this.sectors = response.data;
      })
    },
     openGallery(index) {
      this.$refs.lightbox.showImage(index)
    }
  }

}
</script>